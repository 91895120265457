@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	position: relative;
	min-height: 100%;
	scroll-behavior: smooth;
	overflow-y: scroll;
}

body {
	background-color: #1f252e;
	color: white;
	font-family: "Outfit", sans-serif;
	overflow-x: hidden;
}

button.bg-orange-custom:hover {
	background-color: #178077;
	transition: background-color 0.3s;
}

.animation-pogress {
	animation: anime 2.5s ease-in-out;
}

.grecaptcha-badge {
	visibility: hidden;
}

@keyframes anime {
	from {
		width: 0%;
	}

	to {
		width: 100%;
	}
}

.loader {
	width: 100px;
	aspect-ratio: 0.75;
	--c: no-repeat linear-gradient(#f5f1f1 0 0);
	background: var(--c) 0% 50%, var(--c) 50% 50%, var(--c) 100% 50%;
	animation: l7 1s infinite linear alternate;
}
@keyframes l7 {
	0% {
		background-size: 20% 50%, 20% 50%, 20% 50%;
	}
	20% {
		background-size: 20% 20%, 20% 50%, 20% 50%;
	}
	40% {
		background-size: 20% 100%, 20% 20%, 20% 50%;
	}
	60% {
		background-size: 20% 50%, 20% 100%, 20% 20%;
	}
	80% {
		background-size: 20% 50%, 20% 50%, 20% 100%;
	}
	100% {
		background-size: 20% 50%, 20% 50%, 20% 50%;
	}
}

::-webkit-scrollbar {
	width: 2px;
	height: 2px;
}
::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}
::-webkit-scrollbar-thumb {
	background: #e1e1e1;
	border: 0px none #ffffff;
	border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
	background: #ffffff;
}
::-webkit-scrollbar-thumb:active {
	background: #000000;
}
::-webkit-scrollbar-track {
	background: #666666;
	border: 0px none #ffffff;
	border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
	background: #666666;
}

::-webkit-scrollbar-track:active {
	background: #333333;
}
::-webkit-scrollbar-corner {
	background: transparent;
}

.btn-check {
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 #11998e;
	}
	70% {
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}

.main-text-h1 {
	animation: topSideIn 1s ease forwards;
}

.card-style {
	background-image: linear-gradient(to bottom left, #11998e, #38ef7d);
}

.element:hover .chevron-right {
	transform: translateX(5px);
}

.elements {
	animation: leftSideInElement 1s ease forwards;
}

.main-text-p {
	animation: leftSideInElement 1s ease forwards;
}

.menu-mobile {
	animation: leftSideIn 1s;
}

.options,
.option-menu {
	animation: showBox 0.2s;
}

.cards > div,
.report-menu,
.reserve-template,
.proposal-menu,
.options-menu,
.search-user,
.show {
	animation: showBox 0.5s;
}

.main-img {
	animation: imgAnimation 1s forwards;
}

.show-menu {
	animation: rightSideIn 1s forwards;
}

@keyframes showBox {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes leftSideIn {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes leftSideInElement {
	0% {
		transform: translateX(-100px);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes rightSideIn {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-100%);
		display: none;
	}
}

@keyframes topSideIn {
	0% {
		transform: translateY(-100px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes imgAnimation {
	0% {
		transform: translateX(100px);
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}
